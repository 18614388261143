<template>
  <section>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <v-card-text class="justify-center">
        <v-container fluid>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              dark
              class="mt-2"
              color="#FF3700"
              @click="savedFiles()"
            >
              <v-icon
                dark
                left
              >
                mdi-image-album
              </v-icon>
              Media Files
            </v-btn>
            <banner-upload-list
              ref="callmethod"
              v-model="showSavedBanners"
            />
          </v-col>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row
              align="center"
              justify="center"
            >
              <v-col>
                <v-text-field
                  v-model="campaignName"
                  label="Campaign Name"
                  hint="Name for your reference - 'MarketingCampaign'(Only alpha dashes allowed)."
                  :rules="[(v) => !!v || 'Please enter the campaign name before proceeding.']"
                  type="text"
                  @keydown.space.prevent
                />
              </v-col>
            </v-row>
            <v-row
              v-if="permissionCheck('upload-content')"
              align="center"
              justify="center"
            >
              <v-col
                cols="12"
              >
                <div
                  class="outer-div"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <input
                    id="assetsFieldHandle"
                    ref="file"
                    type="file"
                    multiple
                    name="fields[assetsFieldHandle][]"
                    class="w-px h-px input-file-style"
                    accept=".mp4,.gif,.jpg,.jpeg,.png"
                    @change="onChange"
                  >

                  <label
                    for="assetsFieldHandle"
                    class="block cursor-pointer"
                  >
                    <div class="text-div">
                      Drag and drop files here
                      <v-spacer />
                      <v-icon
                        size="40"
                        color="grey"
                      >
                        mdi-cloud-upload
                      </v-icon>
                    </div>
                  </label>
                  <v-chip
                    v-for="(file, index) in campaignFile"
                    :key="index"
                    class="ma-2"
                    dark
                    color="#E0E0E0"
                    text-color="black"
                    close
                    @click:close="remove(index)"
                  >
                    <v-card
                      class="my-card-in-v-chip"
                      elevation="0"
                    >
                      {{ file.name }}
                    </v-card>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-card v-if="showSelectedFiles">
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="white--text">
                  Selected Files
                </v-toolbar-title>
              </v-toolbar>
              <br>
              <v-chip
                v-for="(item, index) in anySelectedFiles"
                :key="index"
                class="ma-2"
                dark
                close
                color="#E0E0E0"
                text-color="#000000"
                @click:close="removeContent(index)"
              >
                <v-card
                  class="my-card-in-v-chip"
                  elevation="0"
                >
                  {{ item.formData.original_file_name }}
                </v-card>
              </v-chip>
            </v-card>
            <br>
            <v-card-actions>
              <v-btn
                color="red darken-3"
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-spacer />
              <v-btn
                color="#FF3700"
                :disabled="!disableNext"
                @click="uploadCampaign()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </section>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import BannerUploadList from './BannerUploadList.vue';
  import Constants from 'src/constants';
  import global from 'src/mixins/global';

  export default {
    name: 'FileUpload',
    components: {
      'centre-spinner': spinner,
      BannerUploadList,
    },
    mixins: [global],
    data () {
      return {
        campaignName: '',
        loading: false,
        campaignFile: [],
        rotationInterval: 5,
        showSavedBanners: false,
        valid: true,
      };
    },
    computed: {
      disableNext () {
        return this.campaignFile.length > 0 || this.anySelectedFiles.length > 0;
      },
      anySelectedFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
      showSelectedFiles () {
        return this.anySelectedFiles.length > 0;
      },
    },
    mounted () {
      this.$store.dispatch('campaigns/clearCampaignFile');
    },
    methods: {
      savedFiles () {
        if (this.permissionCheck('read-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showSavedBanners = true;
      },
      async uploadCampaign () {
        if (this.$refs.form.validate()) {
          this.loading = true;
          this.$store.dispatch('campaigns/createCampaignName', {
            name: this.campaignName,
          });
          for (var i = 0; i < this.campaignFile.length; i++) {
            const formData = new FormData();
            formData.append('content_file', this.campaignFile[i]);
            formData.append('category_type', 'banner');
            await this.$store.dispatch('campaigns/uploadCampaignFiles', {
              formData: formData,
              rotationInterval: this.rotationInterval,
            }).catch(() => {
              this.loading = false;
              this.$router.push({ name: 'Campaign List' });
            });
          }
          this.$emit('second-step');
          this.campaignName = '';
          this.loading = false;
        }
      },
      onChange () {
        const fi = document.getElementById('assetsFieldHandle');
        if (fi.files.length > 0) {
            for (var i = 0; i < fi.files.length; i++) {
                const fsize = fi.files.item(i).size;
                const file = Math.round((fsize / 1024));
                if (file >= 30096) {
                  const name = fi.files.item(i).name;
                  this.$store.dispatch('alert/onAlert', {
                    message: `File ${name} is too Big, Please select a file of less than 30 Mb.`,
                    type: Constants.ALERT_TYPE_ERROR,
                  }, { root: true });
                } else {
                  this.campaignFile.push(fi.files[i]);
                }
            }
        }
      },
      remove (i) {
        this.campaignFile.splice(i, 1);
      },
      dragover (event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
          event.currentTarget.classList.add('bg-green-300');
        }
      },
      dragleave (event) {
        // Clean up
        event.currentTarget.classList.remove('bg-green-300');
      },
      drop (event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.remove('bg-green-300');
      },
      cancel () {
        this.$router.push({ name: 'Campaign List' });
      },
      removeContent (index) {
        this.$refs.callmethod.removeBadge(index);
        this.$store.dispatch('campaigns/deleteSelectedFile', index);
      },
    },
  };
</script>
<style lang="css" scoped>
[v-cloak] {
  display: none !important;
}
.bg-green-300 {
  background-color: #66BB6A;
}
.input-file-style {
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.outer-div {
  min-height: 120px;
  color: #000;
  border: 3px solid #BDBDBD;
  border-style: dashed;
  border-radius: 20 px;
  border-collapse: separate;
}
.text-div {
  margin-top: 5px;
  text-align: center;
}
.my-card-in-v-chip {
  background-color: #e0e0e0;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 960px) {
.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 52px;
    line-height: 1.3;
}
.my-card-in-v-chip {
  background-color: #e0e0e0;
  color: black;
  margin: 0px;
  max-width: 150px;
}
.outer-div {
  height: auto;
}
}
</style>
