<template>
  <section>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <delete-campaign
        v-if="showDelete"
        :title="'Delete campaign file?'"
        :message="deleteMessage"
        @closed="showDelete = false"
        @confirmed="deleteConfirmed()"
      />
      <v-card-text>
        <p>
          <font class="font-my-style">
            {{ ' Campaign Name: ' + campaignName.name }}
          </font>
        </p>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="campaignFiles"
            class="elevation-1"
            mobile-breakpoint="100"
          >
            <template
              v-slot:item="{ item, index }"
            >
              <tr>
                <td>
                  {{ item.formData.original_file_name }}
                </td>
                <td>{{ item.formData.size | sizeConverter }}</td>
                <td>{{ item.formData.dimension | dimensionFormatter }}</td>
                <td>
                  <div v-if="item.formData.content_type === 'image'">
                    <div class="sliderContainer">
                      <v-slider
                        v-model="item.rotation_interval"
                        max="30"
                        min="2"
                        color="#FF3700"
                        thumb-label
                      />
                      <p class="my-p-style">
                        {{ item.rotation_interval }} seconds
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <font>{{ item.formData.duration | durationConverter }}</font>
                  </div>
                </td>
                <td>
                  <div v-if="isContentImgOrGif(item.formData.content_type)">
                    <v-img
                      width="75"
                      height="75"
                      contain
                      :src="getFilePath(item.formData.file_path)"
                      @click="popUpImage(item.formData.file_path)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF3700"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                  <div
                    v-else
                    class="video-div-style"
                  >
                    <video
                      width="75"
                      height="75"
                      controls
                      contatin
                    >
                      <source
                        :src="getFilePath(item.formData.file_path)"
                        type="video/mp4"
                      >
                    </video>
                  </div>
                </td>
                <td>
                  <v-col>
                    <v-btn
                      class="mt-n2"
                      elevation="1"
                      fab
                      x-small
                      icon
                      color="#C62828"
                      @click.stop
                      @click="deleteCampaignFile(index)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#FF3700"
              @click="reviewSubmit()"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="sucAlert"
      color="success"
      icon="mdi-check-circle"
      transition="scale-transition"
      top
      right
    >
      {{ alertMessage }}
    </v-snackbar>
    <centre-spinner
      :loading="loading"
    />
    <image-preview
      v-if="showPreview"
      :url="previewUrl"
      @closed="showPreview = false"
    />
  </section>
</template>
<script>

  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import { cdnUrl } from 'src/app-globals';
  import ImagePreview from '../component/ImagePreview';
  import Constants from 'src/constants';

  export default {
    name: 'ReviewList',
    components: {
      'centre-spinner': spinner,
      'delete-campaign': DeleteDialog,
      'image-preview': ImagePreview,
    },

    filters: {
      sizeConverter (val) {
        return val / 1000 + ' kb ';
      },
      durationConverter (val) {
        return val / 1000 + ' seconds';
      },
      dimensionFormatter (val) {
        if (val === null) {
          return 'Not available';
        }

        return `${val} px`;
      },
    },

    data () {
      return {
        loading: false,
        showDelete: false,
        deleteIndex: '',
        alertMessage: '',
        deleteMessage: 'This action will permanently remove the item.',
        sucAlert: false,
        headers: [
          { text: 'File Name', value: 'original_file_name' },
          { text: 'Size', value: 'size' },
          { text: 'Dimension', value: 'dimension' },
          { text: 'Rotation Interval', value: 'rotation_interval' },
          { text: 'Content' },
          { text: 'Delete', value: 'delete' },
        ],
        showPreview: false,
        previewUrl: '',
      };
    },
    computed: {
      campaignFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
      campaignName () {
        return this.$store.getters['campaigns/getCampaignName'];
      },
    },
    methods: {
      reviewSubmit () {
        this.loading = true;
        const files = [];
        for (const campaignFile of this.campaignFiles) {
          if (campaignFile.formData.content_type !== 'image') {
            campaignFile.rotation_interval = parseInt(campaignFile.formData.duration) / 1000;
          }
          files.push(campaignFile);
        }
        this.$emit('third-step');
        this.loading = false;
      },
      deleteCampaignFile (index) {
        if (this.campaignFiles.length === 1) {
          this.deleteMessage = 'This is the last content to run the campaign. Deleting will cancel your campaign creation.';
        }
        this.showDelete = true;
        this.deleteIndex = index;
      },
      async deleteConfirmed () {
        await this.$store.dispatch('campaigns/deleteCampaignFile', {
          index: this.deleteIndex,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Campaign file deleted',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          if (this.campaignFiles.length === 0) {
            this.$router.push({ name: 'Campaign List' });
          }
        }).finally(
          this.showDelete = false,
        );
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      popUpImage (fileName) {
        this.previewUrl = this.getFilePath(fileName);
        this.showPreview = true;
      },
      toFileUploadStep () {
        this.$emit('to-first-step');
        this.showAlert = false;
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
    },
  };
</script>

<style scoped>
.sliderContainer {
  width: 150px !important;
  margin: 10px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #CFD8DC
}
.v-data-table {
  background-color: #ECEFF1;
}
.font-my-style {
  font-weight: bold;
  color: #37474F;
  margin-left: 10px;
}
.my-p-style {
  margin-bottom: 0px;
  margin-top: -25px;
  margin-left: 4px;
}
.video-div-style {
  margin: 10px;
  margin-left: -1px;
}
</style>
